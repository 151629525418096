<template>
  <div>
    <my-nav-bar
      title="下次配送"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list v-model="loading" class="list" :finished="finished" @load="getList">
        <div v-for="(orderNext, index) in list" :key="index" class="order-next">
          <van-cell title="操作时间" :value="orderNext.created_at" />
          <van-cell title="客户" :value="orderNext.client.name" />
          <van-cell title="单号" :value="orderNext.order.num" />
          <van-cell title="配送员" :value="orderNext.deliverer.name" />
          <van-cell title="查看详情" is-link center @click="toDetailView(orderNext.id)" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getOrderNexts } from '@/api/order-next'
export default {
  name: 'OrderNext',
  components: { myNavBar },
  data() {
    return {
      listQuery: {
        page: 0
      },
      list: [],
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
    // 监听回传数据
    this.$eventBus.$on('order-next-checked', (orderNextId) => {
      const index = this.list.findIndex(on => on.id === Number(orderNextId))
      if (index !== -1) {
        this.list.splice(index, 1)
      }
      this.showEmpty = this.list.length === 0
    })
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/order-next-detail') {
      this.$store.commit('rmKeepAlive', this.$options.name)
      this.$eventBus.$off('order-next-checked')
    }
    next()
  },
  methods: {
    getList() {
      this.listQuery.page++
      getOrderNexts(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < 10
        this.showEmpty = this.list.length === 0
      })
    },
    toDetailView(orderNextId) {
      this.$router.push({
        path: '/order-next-detail',
        query: {
          order_next_id: orderNextId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 10px;
}
.order-next {
  margin-bottom: 10px;
}
.order-next:nth-last-child(2) {
  margin-bottom: 0!important;
}
</style>
