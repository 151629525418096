import request from '@/utils/request'

export function getOrderNexts(params) {
  return request({
    method: 'get',
    url: 'order-next',
    params
  })
}

export function getOrderNextDetail(id) {
  return request({
    method: 'get',
    url: `order-next/${id}`
  })
}

export function checkOrderNext(id, data) {
  return request({
    method: 'post',
    url: `order-next/${id}`,
    data
  })
}
